import axios from 'axios'
import { ActionListPayload, ActionItemResult } from '@/types/action'
import { UserModeration } from '@/types/userModeration'

export default async function fetchUserModeration({
  id,
  projectId,
}: ActionListPayload): Promise<ActionItemResult<UserModeration>> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }

  const { data } = await axios.get(`/api/v1/user/moderation/${id}`, {
    headers,
    useRetry: false,
  })

  return data
}
