import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { UserModeration } from '@/types/userModeration'

export default async function fetchUsersModeration({
  projectId,
}: ActionListPayload): Promise<ActionListResult<UserModeration>> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }
  const { data } = await axios.get('/api/v1/user/moderation/', {
    headers,
    useRetry: false,
  })

  return data
}
