import * as auth from './auth'
import * as user from './user'
import * as article from './article'
import * as block from './block'
import * as contacts from './contacts'
import * as richHtmlBlock from './rich-html-block'
import * as mailing from './mailing'
import * as video from './video'
import * as fixed from './fixed'
import * as star from './star'
import * as statistic from './statistic'
import * as distribution from './distribution'
import * as socialDistribution from './social-distribution'
import * as articleVideo from './article-video'
import * as podcastEpisode from './podcast-episode'
import * as podcast from './podcast'
import * as transcribation from './transcribation'
import * as podcastDistribution from './podcast-distribution'
import * as dictionaries from './dictionaries'
import * as brandWidget from './brand-widget'
import * as imageTransform from './image-transform'
import * as articleScroll from './article-scroll'
import * as advSwitch from './advSwitch'
import * as advTag from './advTag'
import * as articleBlocks from './articleBlocks'
import * as author from './author'
import * as blocking from './blocking'
import * as changeHistory from './changeHistory'
import * as comment from './comment'
import * as contact from './contact'
import * as contest from './contest'
import * as embed from './embed'
import * as expert from './expert'
import * as fact from './fact'
import * as feature from './feature'
import * as file from './file'
import * as gallery from './gallery'
import * as image from './image'
import * as interest from './interest'
import * as issue from './issue'
import * as mediaLibrary from './mediaLibrary'
import * as photo from './photo'
import * as planCompare from './planCompare'
import * as poll from './poll'
import * as popblock from './popblock'
import * as popup from './popup'
import * as recipe from './recipe'
import * as section from './section'
import * as support from './support'
import * as tag from './tag'
import * as technical from './technical'
import * as test from './test'
import * as videoPartner from './videoPartner'
import * as yandexGpt from './yandexGpt'
import * as videoWidget from './videoWidget'
import * as news from './news'
import * as brand from './brand'
import * as nativePost from './native-post'
import * as userModeration from './userModeration'

const SiriusAPI = {
  auth,
  user,
  article,
  block,
  contacts,
  richHtmlBlock,
  mailing,
  video,
  fixed,
  star,
  statistic,
  distribution,
  socialDistribution,
  articleVideo,
  podcastEpisode,
  podcast,
  transcribation,
  podcastDistribution,
  dictionaries,
  brandWidget,
  imageTransform,
  articleScroll,
  advSwitch,
  advTag,
  articleBlocks,
  author,
  blocking,
  changeHistory,
  comment,
  contact,
  contest,
  embed,
  expert,
  fact,
  feature,
  file,
  gallery,
  image,
  interest,
  issue,
  mediaLibrary,
  photo,
  planCompare,
  poll,
  popblock,
  popup,
  recipe,
  section,
  support,
  tag,
  technical,
  test,
  videoPartner,
  yandexGpt,
  videoWidget,
  news,
  brand,
  nativePost,
  userModeration,
}

export default SiriusAPI
