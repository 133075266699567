import axios from 'axios'

export default async function rejectUserModeration({
  id,
  projectId,
}: {
  id: number
  projectId: number
}) {
  const headers: Record<string, string | number> = {}
  headers['X-Project-ID'] = projectId

  await axios.post(`/api/v1/user/moderation/reject/${id}`, { headers })
}
